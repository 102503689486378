import { initialAbility } from '@/libs/acl/config'
import jwtConfig from '@core/auth/jwt/jwtDefaultConfig'
import ability from '@/libs/acl/ability'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const logout = () => {
  // Remove userData from localStorage
  // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
  localStorage.removeItem(jwtConfig.storageTokenKeyName)
  localStorage.removeItem(jwtConfig.storageRefreshTokenKeyName)

  // Remove userData from localStorage
  localStorage.removeItem('userData')

  // Reset ability
  ability.update(initialAbility)

  // Redirect to login page
  router.push({ name: 'login' })
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole = []) => {
  if (userRole.includes('admin')) return { name: 'product' }
  if (userRole.includes('customer')) return { name: 'product' }
  return { name: 'login' }
}
